<template>
  <div class="bg-white rounded-lg p-4 min-h-50vh flex flex-col">
    <h4 class="font-bold">
      {{ question.question_text.replace("{answer}", "_________") }}
    </h4>

    <div class="mt-6 flex-1">
      <quiz-answer
        v-for="answer in question.answers"
        :class="{
          'bg-brand-primary font-bold shadow-primary-blue border-white':
            answer.uuid === chosenAnswer.uuid
        }"
        :answer="answer.answer_text"
        :key="answer.uuid"
        @click.native="onAnswerClick(answer)"
      />
    </div>

    <t-button
      :disabled="!chosenAnswer.uuid"
      class="mt-10"
      @click="onNextQuestionClick"
      >{{ isLastQ ? "Finish Quiz" : $t("quiz.next_question") }}</t-button
    >
  </div>
</template>

<script>
import QuizAnswer from "components/Quiz/Answer.vue";

export default {
  name: "ActiveQuestion",

  components: {
    QuizAnswer
  },

  props: {
    question: {
      required: true,
      type: Object
    },

    isLastQuestion: {
      type: Boolean
    }
  },

  watch: {
    isLastQuestion(newValue) {
      this.isLastQ = newValue;
    }
  },

  data() {
    return {
      chosenAnswer: {
        uuid: ""
      },
      isLastQ: false
    };
  },

  methods: {
    onAnswerClick(answer) {
      if (answer.uuid === this.chosenAnswer.uuid) return;

      this.chosenAnswer = answer;
    },

    onNextQuestionClick() {
      this.$emit("buttonClick", this.chosenAnswer);
      this.chosenAnswer = {
        uuid: ""
      };
    }
  }
};
</script>

<style scoped></style>
