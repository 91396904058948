<template>
  <div class="bg-white rounded-lg p-4">
    <h2 class="font-bold text-lg">{{ quiz.title }}</h2>

    <div class="flex items-center my-8">
      <mood-good class="w-12 h-12" v-if="hasPassedQuiz" />
      <mood-sad class="w-12 h-12" v-else />
      <p class="mx-8">{{ $t("quiz.result.scored") }}</p>
      <span class="font-bold text-4xl"
        >{{
          quiz.result && quiz.result.quizIsFilledIn
            ? quiz.result.rightAnswers
            : rightAnswers
        }}/{{ totalQuestions }}</span
      >
    </div>

    <p>{{ quiz.content }}</p>

    <div class="mt-12 space-y-2">
      <t-button :to="{ name: 'quiz-index' }">{{
        $t("quiz.result.start_other_quiz")
      }}</t-button>
      <t-button :to="{ name: 'home' }" variant="whiteborder">{{
        $t("quiz.result.back_home")
      }}</t-button>
    </div>
  </div>
</template>

<script>
import MoodGood from "assets/moods/mood_good.svg";
import MoodSad from "assets/moods/mood_sad.svg";
import { mapGetters } from "vuex";

export default {
  name: "Result",

  components: {
    MoodGood,
    MoodSad
  },

  props: {
    quiz: {
      type: Object
    }
  },

  mounted() {},

  destroyed() {
    this.$store.commit("quizzes/RESET_RIGHT_ANSWERS_COUNT");
  },

  computed: {
    ...mapGetters({
      totalQuestions: "quizzes/totalQuestions",
      rightAnswers: "quizzes/rightAnswers"
    }),

    hasPassedQuiz() {
      if (this.quiz.result?.quizIsFilledIn) {
        return (
          this.quiz.result?.rightAnswers >= Math.ceil(this.totalQuestions / 2)
        );
      }
      return this.rightAnswers >= Math.ceil(this.totalQuestions / 2);
    }
  }
};
</script>

<style scoped></style>
