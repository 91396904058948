<template>
  <div class="w-full rounded-full p-4 border mb-4 transition-all">
    {{ answer }}
  </div>
</template>

<script>
export default {
  name: "Answer",
  props: {
    answer: {
      required: true,
      type: String
    }
  }
};
</script>

<style scoped></style>
