<template>
  <wrapper>
    <portal-back router-name="quiz-index" />

    <template v-if="!quizStarted">
      <div class="flex flex-col p-4 bg-white rounded-lg min-h-60vh">
        <h3 class="text-lg font-bold">{{ quiz.title }}</h3>

        <quiz-time v-if="quiz.time" :time="quiz.time" class="my-4" />

        <p v-text="quiz.content" class="flex-1"></p>

        <t-button @click="quizStarted = true">{{
          hasFinishedQuiz ? $t("quiz.check_results") : $t("quiz.start_quiz")
        }}</t-button>
      </div>
    </template>

    <template v-if="quizStarted">
      <template v-if="!hasFinishedQuiz">
        <div v-if="quiz.questions.length">
          <div class="flex space-x-2">
            <span class="font-bold">Question</span>
            <div>
              <span class="font-bold"
                >{{ String(activeIndex + 1).padStart(2, "0") }}/</span
              >{{ String(totalQuestions).padStart(2, "0") }}
            </div>
          </div>

          <div class="flex mt-2 mb-6 space-x-2">
            <div
              v-for="n in quiz.questions.length"
              :key="n"
              class="flex-1 w-full h-1 rounded-full"
              :class="{
                'bg-black': activeIndex + 1 >= n,
                'bg-white bg-opacity-50': activeIndex + 1 < n
              }"
            ></div>
          </div>

          <active-question
            :question="quiz.questions[activeIndex]"
            :is-last-question="isLastQuestion"
            @buttonClick="handleButtonClick"
          />
        </div>

        <template v-else>
          <p class="text-xl font-bold">No questions within this quiz.</p>

          <div class="mt-12 space-y-2">
            <t-button :to="{ name: 'quiz-index' }">{{
              $t("quiz.result.start_other_quiz")
            }}</t-button>
            <t-button :to="{ name: 'home' }" variant="whiteborder">{{
              $t("quiz.result.back_home")
            }}</t-button>
          </div>
        </template>
      </template>

      <quiz-result :quiz="quiz" v-else />
    </template>
  </wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import QuizTime from "components/Quiz/Time.vue";
import QuizResult from "components/Quiz/Result.vue";
import ActiveQuestion from "components/Quiz/ActiveQuestion";

export default {
  name: "Detail",

  components: {
    ActiveQuestion,
    QuizTime,
    QuizResult
  },

  data() {
    return {
      quizStarted: false,
      loading: false,
      hasFinishedQuiz: false,
      activeIndex: 0
    };
  },

  async mounted() {
    await this.$store.dispatch(
      "quizzes/getQuizDetail",
      this.$route.params.uuid
    );

    this.checkIfQuizIsFilled();
  },

  methods: {
    handleButtonClick(answer) {
      if (answer.is_correct_answer) {
        this.$store.commit("quizzes/INCREMENT_RIGHT_ANSWERS");
      }
      if (this.activeIndex + 1 === this.quiz.questions?.length) {
        this.hasFinishedQuiz = true;
        this.$store.dispatch("quizzes/finishQuiz");
        return;
      }
      this.activeIndex++;
    },

    checkIfQuizIsFilled() {
      this.hasFinishedQuiz = this.quiz.result?.quizIsFilledIn;
    }
  },

  computed: {
    ...mapGetters("quizzes", ["quiz", "totalQuestions"]),
    isLastQuestion() {
      return this.activeIndex + 1 === this.quiz.questions?.length;
    }
  }
};
</script>

<style scoped></style>
