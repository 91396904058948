<template>
  <div class="flex items-center space-x-2">
    <icon-clock />
    <span class="text-xs">{{ time }} min</span>
  </div>
</template>

<script>
import IconClock from "assets/icons/clock.svg";

export default {
  name: "Time.vue",
  props: {
    time: {
      required: true,
      type: String
    }
  },
  components: {
    IconClock
  }
};
</script>

<style scoped></style>
